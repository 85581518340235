// Components 

// Styling 
import { useState } from "react"
import "../Styling/AWDWinterdienstSelectCard.css"
import { AWDWinterdienstSelectOption, getSelectOptionData } from "../Data/AWDWinterdienstSelectData"
import { AWDWinterdienstSelectOptionResource } from "../Entity/AWDWinterdienstSelectOptionResource"
import AWDWinterdienstSelectButton from "./AWDWinterdienstSelectButton"
import AWDRoundedLinkButton from "../../Common/Components/LinkButton/AWDRoundedLinkButton"

export default function AWDWinterdienstSelectCard() {

    const [selctedOption, setSelectOption] = useState<AWDWinterdienstSelectOption>(AWDWinterdienstSelectOption.Verwaltung)
    const [selctOptionData, setSelectOptionData] = useState<AWDWinterdienstSelectOptionResource>(getSelectOptionData(selctedOption))

    const handleOptionClick = (option: AWDWinterdienstSelectOption) => {
        setSelectOption(option);
        setSelectOptionData(getSelectOptionData(option));
    };

    const RenderButton = ({ selectedOption }: { selectedOption: AWDWinterdienstSelectOption }) => {
        return selectedOption === AWDWinterdienstSelectOption.Reklamation ? (
            <a
                href="https://www.alpin-berlin.de/winterdienstreklamation/user/login.php"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "orange",
                    border: "none",
                    textDecoration: "none",
                    color: "white",
                    maxWidth: "300px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderRadius: "5px",
                    gridArea: "wdSelctCardButton"
                }}
            >
                Winterdienstreklamation
            </a>
        ) : (
            <AWDRoundedLinkButton
                buttonText="Angebot anfordern"
                link="/angebot"
                gridArea="wdSelctCardButton"
            />
        );
    };

    return ( 
        <div className="winterdienstSelectCardContainer">

            <div className="winterdienstSelectCardGrid">

                <div className="winterdienstSelectGrid">
                    
                    <AWDWinterdienstSelectButton
                        option={AWDWinterdienstSelectOption.Verwaltung}
                        selectedOption={selctedOption}
                        onClick={handleOptionClick}
                    />
                    <AWDWinterdienstSelectButton
                        option={AWDWinterdienstSelectOption.Industrie}
                        selectedOption={selctedOption}
                        onClick={handleOptionClick}
                    />
                    <AWDWinterdienstSelectButton
                        option={AWDWinterdienstSelectOption.Gewerbe}
                        selectedOption={selctedOption}
                        onClick={handleOptionClick}
                    />
                    <AWDWinterdienstSelectButton
                        option={AWDWinterdienstSelectOption.Privatkunde}
                        selectedOption={selctedOption}
                        onClick={handleOptionClick}
                    />
                    <AWDWinterdienstSelectButton
                        option={AWDWinterdienstSelectOption.Reklamation}
                        selectedOption={selctedOption}
                        onClick={handleOptionClick}
                    />
                </div>

                <div className="winterdienstSelectInfoContainer">

                    <div className="winterdienstSelectInfoImageContainer">
                        <img src={selctOptionData.imageSrc} alt={selctOptionData.imageSrc} className="winterdienstSelectImage" />
                    </div>

                    <div className="winterdienstSelectInfoTextContainer">
                        <p className="winterdienstSelectInfoText">
                            {selctOptionData.description}
                        </p>

                        <RenderButton selectedOption={selctedOption} />
                    </div>
                </div>
            </div>

        </div>
    )
}

