
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser"

//Styling 
import "../Styling/AWDAngebotInputFormular.css"
import AWDAngebotTextInput from "./AWDAngebotTextInput";

export default function AWDAngebotInputFormular() {

    const form = useRef<HTMLFormElement | null>(null);
    const [buttonText, setButtonText] = useState<string>("Anfrage abschicken")
    const [buttonColor, setButtonColor] = useState<string>("orange")
    const [isPrivacyAccepted, setIsPrivacyAccepted] = useState<boolean>(false);
    const [privacyColor, setPrivacyColor] = useState<string>('#333333')

    const [objectIndex, setObjectIndex] = useState<boolean[]>([false])

    const handleObjectDetailIndexChange = (index: number) => {
        if (index > 10) { 
            return 
        }
        const updatedObjectIndex = [...objectIndex];
        updatedObjectIndex[index - 1] = !updatedObjectIndex[index - 1];
        updatedObjectIndex[index] = false;
        setObjectIndex(updatedObjectIndex);
    };

    const handlePrivacyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setButtonText("Anfrage abschicken")
        setButtonColor("orange")
        setPrivacyColor('#333333')
        setIsPrivacyAccepted(e.target.checked);
    };

    const sendEmail = async (e: React.FormEvent) => {
        e.preventDefault();

        if (form.current instanceof HTMLFormElement) { 
            const formData = new FormData(form.current); // Create FormData object from the form
            const hasData = Array.from(formData.values()).some(value => {
                if (typeof value === "string") {
                    return value.trim() !== "";
                }
            });
            
            if (!hasData) {
                setButtonText("Bitte geben Sie Daten an")
                setPrivacyColor('red')
            }
        } else { 
            return;
        }

        setButtonText("E-Mail wird gesendet...")
        setButtonColor("gray")

        const rateLimitKey = "emailRateLimit"; 
        const rateLimitDuration = 1 * 60 * 1000; // 15min
        const maxRequests = 5;

        const rateLimitData = JSON.parse(localStorage.getItem(rateLimitKey) || "[]");
        const currentTime = Date.now();

        const validRequests = rateLimitData.filter(
            (timestamp: number) => currentTime - timestamp < rateLimitDuration
        );

        localStorage.setItem(rateLimitKey, JSON.stringify(validRequests));

        if (validRequests.length >= maxRequests) {
            setButtonText("Zu viele Anfragen. Versuche es später erneut.");
            setButtonColor("red");
            return;
        }

        validRequests.push(currentTime);
        localStorage.setItem(rateLimitKey, JSON.stringify(validRequests));

        const emailService: string = process.env.REACT_APP_MAIL_SERVICE ?? '';
        const emailTemplate: string = process.env.REACT_APP_MAIL_TEMPLATE ?? '';
        const email: string = process.env.REACT_APP_MAIL ?? '';

        if (form.current && isPrivacyAccepted) {
          try {
            const response = await emailjs.sendForm(
                emailService,
                emailTemplate,
                form.current,
                email
            );
    
            console.log('Email sent successfully:', response);
            setButtonText("Anfrage abgeschickt")
            setButtonColor("green")
          } catch (error) {
            console.log(error); 
            setButtonText("E-Mail Versand fehlgeschlagen")
          }
        } else { 
            setButtonText("E-Mail Versand fehlgeschlagen")
            setPrivacyColor('red')
        }
      };

    return (
        <div className="angebotInputContainer">
            <form ref={form} onSubmit={sendEmail}>
                <div className="angebotInputGrid">
                    <div className="angebotAdressGrid">
                        <p className="angebotInputTitle">Ihre Anschrift</p>
                        <AWDAngebotTextInput
                            id={"senderName"}
                            name={"user_name"}
                            placeholder={"Name/Firma"}
                            gridArea={"senderName"}
                        />
                        <AWDAngebotTextInput
                            id={"senderStreet"}
                            name={"user_street"}
                            placeholder={"Straße/Hausnr."}
                            gridArea={"senderStreet"}
                        />
                        <AWDAngebotTextInput
                            id={"senderZipCode"}
                            name={"user_zipCode"}
                            placeholder={"PLZ/Ort"}
                            gridArea={"senderZipCode"}
                        />
                        <AWDAngebotTextInput
                            id={"senderPhone"}
                            name={"user_phone"}
                            placeholder={"Telefon"}
                            gridArea={"senderPhone"}
                        />
                        <AWDAngebotTextInput
                            id={"senderMail"}
                            name={"user_email"}
                            placeholder={"E-Mail"}
                            gridArea={"senderMail"}
                        />
                    </div>

                    <div className="angebotObjectGrid">
                        <p className="angebotInputTitle" id="angebotInputTitleObject">Ihre Objektdaten</p>
                        <AWDAngebotTextInput
                            id={"objectAdress"}
                            name={"user_object_adress"}
                            placeholder={"Straße/Hausnr."}
                            gridArea={"objectAdress"}
                        />
                        <AWDAngebotTextInput
                            id={"objectZipCode"}
                            name={"user_object_zipCode"}
                            placeholder={"PLZ"}
                            gridArea={"objectZipCode"}
                        />
                        <p className="angebotExtraTitle">Berlin</p>
                    </div>

                    <div className="angebotObjectDetailGridContainer">
                        <p className="angebotInputTitle">{objectIndex.length === 1 ? "Ihr Objektgegenstand" : "Ihre Objektgegenstände"} </p>
                        {objectIndex.map((value, index) => (
                            <ObjectInput index={index + 1} increaseIndex={handleObjectDetailIndexChange} />
                        ))}
                    </div>

                    <div className="angebotObjectMessageGrid">
                        <p className="angebotInputTitle">Sonstiges</p>
                        <AWDAngebotTextInput
                            id={"objectDetailMessage"}
                            name={"user_object_message"}
                            placeholder={"Sonstiges"}
                            gridArea={"objectDetailMessage"}
                        />
                    </div>

                    <div className="angebotDatenschutzContainer">
                        <input type="radio" onChange={handlePrivacyChange} checked={isPrivacyAccepted}></input>
                        <p style={{color: privacyColor}}>Die Datenschutzerklärung habe ich zur Kenntnis genommen und akzeptiert</p>
                    </div>

                    <button
                        className="angebotSendMailButton"
                        onClick={sendEmail}
                        style={{backgroundColor: buttonColor}}
                    >
                        {buttonText}
                    </button>
                </div>
            </form>
        </div>
    )
}

function ObjectInput(
    props: { 
        index: number,
        increaseIndex: (index: number) => void;
    }
) {

    const index = props.index;

    const handleObjectDetailChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        console.log("increase default")
        if (selectedValue !== "default") {
            props.increaseIndex(index);
        }
    };

    const objectDetailTypes = [
        ["default", "Bitte auswählen"],
        ["Straßenfront", "Gehweg außen/Straßenfront "],
        ["Hauszugang", "Hauszugang"],
        ["Gehwegzufahrt", "Gehwegzufahrt"],
        ["Stellplatz", "Stellplatz"],
        ["Innenhof", "Weg im Innenhof"],
        ["Mülltonnenstellplatz", "Mülltonnenstellplatz"]
    ];

    return (
        <>
            <div className="angebotObjectDetailGrid" style={{ gridArea: "objectDetailGrid" + index }}>
                <select
                    id="objectDeatilType"
                    name={"user_object_type_" + index} 
                    style={{ gridArea: "objectType" }}
                    onChange={handleObjectDetailChange}
                >
                    {objectDetailTypes.map((objectType, index) => (
                        <option value={objectType[0]}>{objectType[1]}</option>
                    ))}
                </select>
                <AWDAngebotTextInput
                    id={"objectDeatilLenght"}
                    name={"user_object_lenght_" + index}
                    placeholder={"Länge (m)"}
                    gridArea={"objectLenght"}
                />
                <AWDAngebotTextInput
                    id={"objectDeatilWidth"}
                    name={"user_object_width_" + index}
                    placeholder={"Breite (m)"}
                    gridArea={"objectWidth"}
                />
                <AWDAngebotTextInput
                    id={"objectDeatilCount"}
                    name={"user_object_count_" + index}
                    placeholder={"Anzahl"}
                    gridArea={"objectCount"}
                />
            </div>
        </>
    )
}