import AWDPageFooter from "../../Common/Components/PageFooter/AWDPageFooter";
import AWDPageNavbar from "../../Common/Components/PageNavbar/AWDPageNavbar";
import AWDTextContainer from "../../Contact/Components/AWDTextContainer";
import AWDTextLinkContainer from "../../Contact/Components/AWDTextLinkContainer";

// Styling 
import "../Styling/AWDDatenschutzPage.css"

export default function AWDDatenschutzPage() {
    return (
        <div className="AWDPageContainer">
            <AWDPageNavbar />
            <div className="AWDBasicPageContent">
                <p className="basicPageTitle">Datenschutzerklärung der Alpin GmbH</p>
                <Einleitung />
                <Verantwortlicher />
                <Data /> 
                <Law /> 
                <ThirdPartyData /> 
                <Auftragsdatenverarbeitung /> 
                <Speicherung /> 
                <Datenspeicherung /> 
                <Rights /> 
                <Sicherheit /> 
                <Änderungen /> 
                <Kontakt /> 
                <Date /> 
            </div>
            <AWDPageFooter />
        </div>
    )
}

function Einleitung() {
    return (
        <>
            <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>1. Einleitung</p>
            <p className="basicPageText">
                Wir, die Alpin GmbH, nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst. 
                Mit dieser Datenschutzerklärung informieren wir Sie darüber, welche Daten wir erheben, 
                wie wir diese verwenden und welche Rechte Sie diesbezüglich haben.
            </p>
        </>
    )
}

function Verantwortlicher() {
    return (
        <>
            <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>2. Verantwortlicher</p>
            <p className="basicPageText">Verantwortlich für die Datenverarbeitung auf dieser Website ist:</p>
            <p className="basicPageText">Alpin GmbH</p>
            <p className="basicPageText">Späthstr. 21-23</p>
            <p className="basicPageText">12359 Berlin</p>
            <p className="basicPageText">E-Mail: mail@alpin-berlin.de</p>
            <p className="basicPageText">Telefon: +49  30 6843601</p>
            <br />
            <p className="basicPageText">Datenschutzbeauftragter der Alpin GmbH:</p>
            <p className="basicPageText">Hans-Peter Mielke mit Sitz in 12279 Berlin</p>
            <p className="basicPageText">info@hpm-datenschutz.de</p>
        </>
    )
}

function Data() { 
    return (
        <> 
            <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                3. Erhebung und Verarbeitung personenbezogener Daten
            </p>
            <p className="basicPageText">
                Wir verarbeiten personenbezogene Daten nur im gesetzlich zulässigen Rahmen. Dazu gehört:
            </p>
            <p className="basicPageText">
                - Besuch der Website (IP-Adresse, Browsertyp, Betriebssystem, Referrer-URL, Zugriffszeitpunkt)
            </p>
            <p className="basicPageText">
                - Kontaktaufnahme per E-Mail oder Kontaktformular (Name, E-Mail-Adresse, Anfrageinhalte)
            </p>
            <p className="basicPageText">
                - Kunden- und Vertragsverwaltung (Name, Adresse, Zahlungsinformationen, Kommunikationshistorie)
            </p>
            <p className="basicPageText">
                - Nutzung unserer Dienste (Nutzungsdaten, Login-Daten, Support-Anfragen)
            </p>

            <br />

            <p className="basicPageText" style={{ fontWeight: 600 }}>
                4. Zwecke der Datenverarbeitung
            </p>
            <p className="basicPageText">
                Ihre personenbezogenen Daten werden zu folgenden Zwecken verarbeitet:
            </p>
            <p className="basicPageText">
                - Bereitstellung und Optimierung unserer Website
            </p>
            <p className="basicPageText">
                - Bearbeitung von Anfragen
            </p>
            <p className="basicPageText">
                - Erbringung unserer vertraglichen Leistungen
            </p>
            <p className="basicPageText">
                - Erfüllung gesetzlicher Verpflichtungen
            </p>
            <p className="basicPageText">
                - Verbesserung unserer Services
            </p>
        </>
    )
}

function Law() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                5. Rechtsgrundlagen der Verarbeitung
            </p>
            <p className="basicPageText">
                Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Basis der folgenden Rechtsgrundlagen:
            </p>
            <p className="basicPageText">
                - Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)
            </p>
            <p className="basicPageText">
                - Art. 6 Abs. 1 lit. b DSGVO (Vertragserfüllung)
            </p>
            <p className="basicPageText">
                - Art. 6 Abs. 1 lit. c DSGVO (gesetzliche Verpflichtung)
            </p>
            <p className="basicPageText">
                - Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse)
            </p>
        </>
    )
}

function ThirdPartyData() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                6. Weitergabe von Daten
            </p>
            <p className="basicPageText">
                Wir geben Ihre personenbezogenen Daten nur weiter, wenn:
            </p>
            <p className="basicPageText">
                - dies zur Erfüllung vertraglicher Verpflichtungen erforderlich ist
            </p>
            <p className="basicPageText">
                - wir gesetzlich dazu verpflichtet sind oder
            </p>
            <p className="basicPageText">
                - Sie Ihre ausdrückliche Einwilligung gegeben haben.
            </p>
        </>
    )
}

function Auftragsdatenverarbeitung() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                7. Auftragsdatenverarbeitung
            </p>
            <p className="basicPageText">
                Soweit wir Dienstleister (z. B. Hosting-Anbieter, Zahlungsdienstleister) einsetzen, 
                werden diese im Rahmen einer Auftragsdatenverarbeitung gemäß Art. 28 DSGVO vertraglich verpflichtet, 
                Ihre Daten nur in unserem Auftrag und entsprechend den gesetzlichen Vorgaben zu verarbeiten.
            </p>
        </>
    )
}

function Speicherung() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                8. Speicherung, Übertragung und Standort der Daten
            </p>
            <p className="basicPageText">
                Wir legen großen Wert darauf, dass Ihre Daten sicher und DSGVO-konform gespeichert werden. 
                Die von uns erhobenen Informationen werden sowohl auf unseren eigenen Servern als auch bei 
                unserem bewährten Partner Microsoft gespeichert. Unsere Server befinden sich in Deutschland, 
                während Microsoft Rechenzentren innerhalb der Europäischen Union nutzt. Dies gewährleistet, 
                dass Ihre Daten jederzeit nach den strengen europäischen Datenschutzvorschriften behandelt werden.
            </p>
            <br />
            <p className="basicPageText">
                Zusätzlich werden alle Übertragungen Ihrer Daten mit modernen Verschlüsselungstechnologien geschützt. 
                Dadurch stellen wir sicher, dass Ihre Informationen während der Übermittlung nicht von unbefugten 
                Dritten eingesehen oder manipuliert werden können. Dies gilt sowohl für den Datenaustausch mit 
                unserer Website als auch für interne Datenübertragungen innerhalb unserer Systeme und zu unseren Dienstleistern.
            </p>
            <br />
            <p className="basicPageText">
                Durch diese Kombination können wir Ihnen sowohl eine hohe Sicherheit als auch eine stabile IT-Infrastruktur bieten. 
                Die Speicherung bei Microsoft erlaubt es uns zudem, auf leistungsstarke, moderne Technologien zur Datenverwaltung 
                zurückzugreifen, ohne dabei den Schutz Ihrer Daten aus den Augen zu verlieren. Selbstverständlich haben wir 
                mit Microsoft entsprechende Verträge zur Auftragsverarbeitung abgeschlossen, die sicherstellen, dass Ihre Daten 
                nur in unserem Auftrag und nach unseren Vorgaben verarbeitet werden.
            </p>
        </>
    )
}

function Datenspeicherung() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                9. Datenspeicherung und Löschung
            </p>
            <p className="basicPageText">
                Ihre personenbezogenen Daten werden nur so lange gespeichert, wie es für die genannten 
                Zwecke erforderlich ist oder gesetzliche Aufbewahrungspflichten bestehen. Danach werden 
                die Daten gelöscht.
            </p>
        </>
    )
}

function Rights() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                10. Ihre Rechte
            </p>
            <p className="basicPageText">
                - Auskunft über Ihre gespeicherten Daten (Art. 15 DSGVO)
            </p>
            <p className="basicPageText">
                - Berichtigung falscher Daten (Art. 16 DSGVO)
            </p>
            <p className="basicPageText">
                - Löschung Ihrer Daten (Art. 17 DSGVO)
            </p>
            <p className="basicPageText">
                - Einschränkung der Verarbeitung (Art. 18 DSGVO)
            </p>
            <p className="basicPageText">
                - Datenübertragbarkeit (Art. 20 DSGVO)
            </p>
            <p className="basicPageText">
                - Widerspruch gegen die Verarbeitung (Art. 21 DSGVO)
            </p>
        </>
    )
}

function Sicherheit() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                11. Sicherheit der Daten
            </p>
            <p className="basicPageText">
                Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, 
                um Ihre Daten gegen unbefugten Zugriff, Verlust oder Manipulation zu schützen.
            </p>
        </>
    )
}

function Änderungen() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                12. Änderungen der Datenschutzerklärung
            </p>
            <p className="basicPageText">
                Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf 
                zu aktualisieren. Die jeweils aktuelle Version finden Sie auf unserer Website.
            </p>
        </>
    )
}

function Kontakt() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText" style={{ fontWeight: 600 }}>
                13. Kontakt
            </p>
            <p className="basicPageText">
                Falls Sie Fragen zu dieser Datenschutzerklärung oder zur Verarbeitung Ihrer Daten haben, 
                können Sie uns unter den oben angegebenen Kontaktdaten erreichen.
            </p>
        </>
    )
}

function Date() { 
    return ( 
        <> 
        <br />
            <p className="basicPageText">
                Stand: 29.03.2025
            </p>
        </>
    )
}
